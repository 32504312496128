import { backend } from "config/environment";

export const routesIndex = {
  "/clients": "1",
  "/file-browser": "2",
  "/folder-browser": "3",
};

export const apiRoute = backend.apiRoute;

export const rfcPattern =
  /^([A-ZÑ&]{3,4}) ?(?:- ?)?(\d{2}(?:0[1-9]|1[0-2])(?:0[1-9]|[12]\d|3[01])) ?(?:- ?)?([A-Z\d]{2})([A\d])$/;

export const rfcValidationRule = {
  required: true,
  message: "Ingrese un RFC válido",
  pattern: new RegExp(rfcPattern),
};

export const onBoardingExcels = [
  {
    name: "Calendario Nominas",
    url: "https://audi-imss-bucket.s3.us-east-2.amazonaws.com/Plantillas/CalendarioNominas.xlsx",
    type: "xlsx",
  },
  {
    name: "Factores de Antigüedad",
    url: "https://audi-imss-bucket.s3.us-east-2.amazonaws.com/Plantillas/Factores+de+Antiguedad.xlsx",
    type: "xlsx",
  },
  {
    name: "SELECCIÓN DE PERSONAL",
    url: "https://audi-imss-bucket.s3.us-east-2.amazonaws.com/Plantillas/SELECCION+DE+PERSONAL.xlsx",
    type: "xlsx",
  },
];

export const currencies = Object.freeze({
  mxn: "mxn",
  usd: "usd",
});

export const depositTypes = {
  internal: "internal",
  external: "external",
};

export const termsAndConditions = {
  path: "https://audi-imss-misc.s3.amazonaws.com/templates/TERMINOS+Y+CONDICIONES+AUDI-IMSS+(1).pdf",
};

export const monthNames = [
  { name: "Enero", number: 1 },
  { name: "Febrero", number: 2 },
  { name: "Marzo", number: 3 },
  { name: "Abril", number: 4 },
  { name: "Mayo", number: 5 },
  { name: "Junio", number: 6 },
  { name: "Julio", number: 7 },
  { name: "Agosto", number: 8 },
  { name: "Septiembre", number: 9 },
  { name: "Octubre", number: 10 },
  { name: "Noviembre", number: 11 },
  { name: "Diciembre", number: 12 },
];

export const formatCurrency = (money) => {
  if (!money) return;
  const { amount, exponent, currency } = money;
  return new Intl.NumberFormat("es-MX", {
    style: "currency",
    currency,
  }).format(amount / Math.pow(10, exponent));
};

export const serviceStatus = Object.freeze({
  inProgress: { name: "En progreso", value: "inProgress" },
  auditing: { name: "Auditando", value: "auditing" },
  completed: { name: "Completado", value: "completed" },
});

export const paymentStatus = Object.freeze({
  pending: { name: "Pendiente", value: "pending" },
  completed: { name: "Pagado", value: "completed" },
});

export const servicesTypes = Object.freeze({
  month: { name: "Mensual", value: "month" },
  year: { name: "Anual", value: "year" },
  both: { name: "Ambos", value: "both" },
});

export const usesCFDI = {
  "G01 - Adquisición de mercancías": "G01 - Adquisición de mercancías",
  "G02 - Devoluciones, descuentos o bonificaciones":
    "G02 - Devoluciones, descuentos o bonificaciones",
  "G03 - Gastos en general": "G03 - Gastos en general",
  "I01 - Construcciones": "I01 - Construcciones",
  "I02 - Mobiliario y equipo de oficina por inversiones":
    "I02 - Mobiliario y equipo de oficina por inversiones",
  "I03 - Equipo de transporte": "I03 - Equipo de transporte",
  "I04 - Equipo de cómputo y accesorios":
    "I04 - Equipo de cómputo y accesorios",
  "I05 - Dados, troqueles, moldes, matrices y herramientas":
    "I05 - Dados, troqueles, moldes, matrices y herramientas",
  "I06 - Comunicaciones telefónicas": "I06 - Comunicaciones telefónicas",
  "I07 - Comunicaciones satelitales": "I07 - Comunicaciones satelitales",
  "I08 - Otra maquinaria y equipo": "I08 - Otra maquinaria y equipo",
  "D01 - Honorarios médicos, dentales y gastos hospitalarios":
    "D01 - Honorarios médicos, dentales y gastos hospitalarios",
  "D02 - Gastos médicos por incapacidad o discapacidad":
    "D02 - Gastos médicos por incapacidad o discapacidad",
  "D03 - Gastos funerales": "D03 - Gastos funerales",
  "D04 - Donativos": "D04 - Donativos",
  "D05 - Intereses reales efectivamente pagados por créditos hipotecarios":
    "D05 - Intereses reales efectivamente pagados por créditos hipotecarios",
  "D06 - Aportaciones voluntarias al SAR":
    "D06 - Aportaciones voluntarias al SAR",
  "D07 - Primas por seguros de gastos médicos":
    "D07 - Primas por seguros de gastos médicos",
  "D08 - Gastos de transportación escolar obligatoria":
    "D08 - Gastos de transportación escolar obligatoria",
  "D09 - Depósitos en cuentas para el ahorro, primas que tengan como base planes de pensiones":
    "D09 - Depósitos en cuentas para el ahorro, primas que tengan como base planes de pensiones",
  "D10 - Pagos por servicios educativos (colegiaturas)":
    "D10 - Pagos por servicios educativos (colegiaturas)",
  "P01 - Por definir": "P01 - Por definir",
};

export const taxRegimes = {
  "601 - General de Ley Personas Morales":
    "601 - General de Ley Personas Morales",
  "603 - Personas Morales con Fines no Lucrativos":
    "603 - Personas Morales con Fines no Lucrativos",
  "605 - Sueldos y Salarios e Ingresos Asimilados a Salarios":
    "605 - Sueldos y Salarios e Ingresos Asimilados a Salarios",
  "606 - Arrendamiento": "606 - Arrendamiento",
  "607 - Régimen de Enajenación o Adquisición de Bienes":
    "607 - Régimen de Enajenación o Adquisición de Bienes",
  "608 - Demás ingresos": "608 - Demás ingresos",
  "609 - Consolidación": "609 - Consolidación",
  "610 - Residentes en el Extranjero sin Establecimiento Permanente en México":
    "610 - Residentes en el Extranjero sin Establecimiento Permanente en México",
  "611 - Ingresos por Dividendos (socios y accionistas)":
    "611 - Ingresos por Dividendos (socios y accionistas)",
  "612 - Personas Físicas con Actividades Empresariales y Profesionales":
    "612 - Personas Físicas con Actividades Empresariales y Profesionales",
  "614 - Ingresos por intereses": "614 - Ingresos por intereses",
  "615 - Régimen de los ingresos por obtención de premios":
    "615 - Régimen de los ingresos por obtención de premios",
  "616 - Sin obligaciones fiscales": "616 - Sin obligaciones fiscales",
  "620 - Sociedades Cooperativas de Producción que optan por diferir sus ingresos":
    "620 - Sociedades Cooperativas de Producción que optan por diferir sus ingresos",
  "621 - Incorporación Fiscal": "621 - Incorporación Fiscal",
  "622 - Actividades Agrícolas, Ganaderas, Silvícolas y Pesqueras":
    "622 - Actividades Agrícolas, Ganaderas, Silvícolas y Pesqueras",
  "623 - Opcional para Grupos de Sociedades":
    "623 - Opcional para Grupos de Sociedades",
  "624 - Coordinados": "624 - Coordinados",
  "625 - Régimen de las Actividades Empresariales con ingresos a través de Plataformas Tecnológicas":
    "625 - Régimen de las Actividades Empresariales con ingresos a través de Plataformas Tecnológicas",
  "626 - Régimen Simplificado de Confianza":
    "626 - Régimen Simplificado de Confianza",
};
