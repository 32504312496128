import React, { useEffect, useState } from "react";
import {
  Modal,
  Tabs,
  Form,
  Input,
  InputNumber,
  Typography,
  Button,
  message,
} from "antd";
import {
  PhoneOutlined,
  UserOutlined,
  IdcardOutlined,
  UsergroupAddOutlined,
} from "@ant-design/icons";
import theme from "theme/index";
import { rfcValidationRule } from "utils/constants";
import { UPDATE_USER, UPDATE_ORGANIZATION } from "./requests";
import { useMutation } from "@apollo/client";
import console from "console-browserify";

const { TabPane } = Tabs;
const { Text } = Typography;

const SettingsModal = ({ visible, setVisible, user, userRefetch }) => {
  const [updateUserMutation] = useMutation(UPDATE_USER);
  const [updateOrganizationMutation] = useMutation(UPDATE_ORGANIZATION);

  const [formData, setFormData] = useState({
    phone: "",
    name: "",
    lastName: "",
    rfc: "",
    numberOfEmployees: 0,
    businessName: "",
  });
  const [loadingUpdate, setLoadingUpdate] = useState(false);

  useEffect(() => {
    if (user) {
      setFormData({
        phone: user.phone || "",
        name: user.name || "",
        lastName: user.lastName || "",
        rfc: user.organization ? user.organization.rfc || "" : "",
        numberOfEmployees: user.organization
          ? user.organization.numberOfEmployees || ""
          : 0,
        businessName: user.organization ? user.organization.name || "" : "",
      });
    }
  }, [visible, user]);

  const handleInputChange = (fieldName, value) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      [fieldName]: value,
    }));
  };

  const updateData = async () => {
    setLoadingUpdate(true);

    try {
      const payloadUser = {
        variables: {
          id: user?.id,
          user: {
            name: formData?.name,
            lastName: formData?.lastName,
            phone: formData?.phone,
          },
        },
      };
      await updateUserMutation(payloadUser);

      const payloadOrg = {
        variables: {
          id: user?.organization?.id,
          organization: {
            name: formData?.businessName,
            rfc: formData?.rfc,
            numberOfEmployees: formData?.numberOfEmployees,
          },
        },
      };
      await updateOrganizationMutation(payloadOrg);
      await userRefetch();
      message.success("Operación realizada con éxito");
      setVisible(false);
      setLoadingUpdate(false);
    } catch (err) {
      message.error("Hubo un error");
      console.log(err);
    }
  };

  const handleCancel = () => {
    setVisible(false);
  };

  return (
    <Modal
      title="Editar Datos de Usuario y Organización"
      visible={visible}
      onCancel={handleCancel}
      footer={[
        <Button
          key="update"
          type="primary"
          onClick={updateData}
          loading={loadingUpdate}
        >
          Actualizar Datos
        </Button>,
      ]}
    >
      <Tabs defaultActiveKey="1">
        <TabPane tab="Datos de cliente" key="1">
          <Form name="userForm" layout="vertical" initialValues={formData}>
            <div style={{ marginBottom: 16 }}>
              <Text style={{ fontSize: 13 }} strong>
                Nombre
              </Text>
              <Form.Item
                name="name"
                rules={[
                  {
                    required: true,
                    message: "Por favor ingrese el Nombre",
                  },
                ]}
              >
                <Input
                  size="large"
                  prefix={<UserOutlined style={{ color: "#C52790" }} />}
                  placeholder="Nombre"
                  onChange={(e) => handleInputChange("name", e.target.value)}
                />
              </Form.Item>
            </div>
            <div style={{ marginBottom: 16 }}>
              <Text style={{ fontSize: 13 }} strong>
                Apellido
              </Text>
              <Form.Item
                name="lastName"
                rules={[
                  {
                    required: true,
                    message: "Por favor ingrese un Apellido",
                  },
                ]}
              >
                <Input
                  size="large"
                  prefix={<UserOutlined style={{ color: "#C52790" }} />}
                  placeholder="Apellido"
                  onChange={(e) =>
                    handleInputChange("lastName", e.target.value)
                  }
                />
              </Form.Item>
            </div>
            <div style={{ marginBottom: 16 }}>
              <Text style={{ fontSize: 13 }} strong>
                Teléfono
              </Text>
              <Form.Item
                name="phone"
                rules={[
                  {
                    required: true,
                    message: "Por favor ingrese el Teléfono",
                  },
                  {
                    pattern: new RegExp(/^[0-9\b]+$/),
                    message: "El Teléfono solo puede contener números",
                  },
                ]}
              >
                <Input
                  size="large"
                  prefix={
                    <PhoneOutlined style={{ color: theme.colors.primary }} />
                  }
                  placeholder="Teléfono"
                  onChange={(e) => handleInputChange("phone", e.target.value)}
                />
              </Form.Item>
            </div>
          </Form>
        </TabPane>
        <TabPane tab="Datos de la organización" key="2">
          <Form
            name="organizationForm"
            layout="vertical"
            initialValues={formData}
          >
            <div style={{ marginBottom: 16 }}>
              <Text style={{ fontSize: 13 }} strong>
                Nombre de la Organización
              </Text>
              <Form.Item
                name="businessName"
                rules={[
                  {
                    required: true,
                    message: "Por favor ingrese el Nombre",
                  },
                ]}
              >
                <Input
                  size="large"
                  prefix={<UserOutlined style={{ color: "#C52790" }} />}
                  placeholder="Nombre"
                  onChange={(e) =>
                    handleInputChange("businessName", e.target.value)
                  }
                />
              </Form.Item>
            </div>
            <div style={{ marginBottom: 16 }}>
              <Text style={{ fontSize: 13 }} strong>
                RFC
              </Text>
              <Form.Item
                name="rfc"
                rules={[
                  {
                    required: true,
                    message: "Por favor ingrese el RFC",
                  },
                  rfcValidationRule,
                ]}
              >
                <Input
                  size="large"
                  prefix={<IdcardOutlined style={{ color: "#C52790" }} />}
                  placeholder="RFC"
                  onChange={(e) => handleInputChange("rfc", e.target.value)}
                />
              </Form.Item>
            </div>
            <div style={{ marginBottom: 16 }}>
              <Text style={{ fontSize: 13 }} strong>
                Número de Empleados
              </Text>
              <Form.Item
                name="numberOfEmployees"
                rules={[
                  {
                    required: true,
                    message: "Por favor ingrese el Número de Empleados",
                  },
                ]}
              >
                <InputNumber
                  size="large"
                  style={{ width: "100%" }}
                  prefix={<UsergroupAddOutlined style={{ color: "#C52790" }} />}
                  placeholder="Número de Empleados"
                  onChange={(value) =>
                    handleInputChange("numberOfEmployees", value)
                  }
                />
              </Form.Item>
            </div>
          </Form>
        </TabPane>
      </Tabs>
    </Modal>
  );
};

export default SettingsModal;
