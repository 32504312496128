import media from "./media";

const theme = {
  media,
  colors: {
    primary: "#C52790",
    secondary: "#13192D",
  },
};

export default theme;
