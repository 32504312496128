/* eslint-disable no-unused-vars */
import React from "react";
import { Routes, Route } from "react-router-dom";
import Private from "./views/private";
import Authentication from "./views/private/routes/auth/routes/login";
import TopBarProgress from "react-topbar-progress-indicator";
import Loadable from "react-loadable";
import "antd/dist/reset.css";
import "index.css";

const Folders = Loadable({
  loader: () => import("./views/private/routes/crm/routes/folders"),
  loading: TopBarProgress,
});

const Files = Loadable({
  loader: () => import("./views/private/routes/crm/routes/files"),
  loading: TopBarProgress,
});
const Clients = Loadable({
  loader: () => import("./views/private/routes/crm/routes/clients"),
  loading: TopBarProgress,
});

const Organizations = Loadable({
  loader: () => import("./views/private/routes/crm/routes/organizations"),
  loading: TopBarProgress,
});

const RegistrationProcess = Loadable({
  loader: () =>
    import("./views/private/routes/crm/routes/registration-process"),
  loading: TopBarProgress,
});

const Reports = Loadable({
  loader: () => import("./views/private/routes/crm/routes/reports"),
  loading: TopBarProgress,
});

const Discounts = Loadable({
  loader: () => import("./views/private/routes/crm/routes/discounts"),
  loading: TopBarProgress,
});

const Services = Loadable({
  loader: () => import("./views/private/routes/crm/routes/services"),
  loading: TopBarProgress,
});

const Payments = Loadable({
  loader: () => import("./views/private/routes/crm/routes/payments"),
  loading: TopBarProgress,
});

const BusinessRun = Loadable({
  loader: () => import("./views/private/routes/crm/routes/business-run"),
  loading: TopBarProgress,
});

const App = () => {
  return (
    <Routes>
      <Route path="/login" element={<Authentication />}></Route>
      <Route path="/" element={<Private />}>
        <Route path="/folders" element={<RegistrationProcess />}>
          <Route path=":id" element={<Folders />}></Route>
        </Route>
        <Route path="/files">
          <Route path=":folderId" element={<Files />}></Route>
        </Route>
        <Route path="/clients" element={<Clients />}></Route>
        <Route path="/organizations" element={<Organizations />}></Route>
        <Route path="/reports" element={<Reports />}></Route>
        <Route path="/discounts" element={<Discounts />}></Route>
        <Route path="/services" element={<Services />}></Route>
        <Route path="/payments" element={<Payments />}></Route>
        <Route path="/business-run" element={<BusinessRun />}></Route>
      </Route>
    </Routes>
  );
};

export default App;
