import urljoin from "url-join";

const env = {
  development: process.env.NODE_ENV === "development",
  production: process.env.NODE_ENV === "production",
};

const backend = {
  AUTH_URI: process.env.REACT_APP_SERVER_URI,
  HTTP_URI: urljoin(process.env.REACT_APP_SERVER_URI ?? "", "graphql"),
  WS_URI: urljoin(process.env.REACT_APP_WS_URI ?? "", "graphql"),
  apiRoute: process.env.REACT_APP_API_ROUTE,
};

const publicNamespace = process.env.REACT_APP_PUBLIC_NAMESPACE;

const conektaConfig = {
  publicApiKey: process.env.REACT_APP_CONEKTA_PUBLIC_API_KEY,
};

export { env, backend, publicNamespace, conektaConfig };
