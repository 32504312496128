import styled, { keyframes } from "styled-components";

const HeaderKeyFrame = keyframes`
    0% {
        background-position: 0% 50%;
    }
    50% {
        background-position: 100% 50%;
    }
    100% {
        background-position: 0% 50%;
    }
`;

const AuthWrapper = styled.div`
  min-height: 100vh;
  width: 100vw;
    background-color:white;
  }
`;

const DisplayWrapper = styled.div`
  min-height: 100vh;
  width: 100%;
  background: linear-gradient(to right, #C52790, #EE3831, #F89920);
  animation: ${HeaderKeyFrame} 10s ease infinite;
  background-size: 200% 100%;
  position: relative; /* Added this line */
}
`;

const ImageContainer = styled.div`
  position: absolute;
  bottom: 0;
  right: 0;
  padding-left: 30vh;
`;

export { AuthWrapper, DisplayWrapper, ImageContainer };
