import React, { lazy, Suspense } from "react";
import TopBarProgress from "react-topbar-progress-indicator";
import AuthLayout from "../../../../../../layout/auth/index.jsx";

const Login = lazy(() => import("./components/index.jsx"));

const Auth = () => {
  return (
    <AuthLayout>
      <Suspense fallback={<TopBarProgress />}>
        <Login />
      </Suspense>
    </AuthLayout>
  );
};

export default React.memo(Auth);
