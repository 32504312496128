import { gql } from "@apollo/client";

const UPDATE_USER = gql`
  mutation updateUser($id: ID!, $user: UserUpdate!) {
    updateUser(id: $id, user: $user) {
      id
    }
  }
`;

const UPDATE_ORGANIZATION = gql`
  mutation updateOrganization($id: ID!, $organization: OrganizationUpdate) {
    updateOrganization(id: $id, organization: $organization) {
      id
    }
  }
`;

const UPDATE_PASSWORD = gql`
  mutation updatePassword(
    $id: ID
    $currentPassword: String
    $newPassword: String
  ) {
    updatePassword(
      id: $id
      currentPassword: $currentPassword
      newPassword: $newPassword
    )
  }
`;

export { UPDATE_USER, UPDATE_ORGANIZATION, UPDATE_PASSWORD };
