import { gql } from "@apollo/client";

const GET_USER = gql`
  query user($id: ID!) {
    user(id: $id) {
      id
      email
      name
      lastName
      type
      isEnterprise
      organization {
        termsAndConditionsAccepted
        businessRuns {
          id
          businessName
          rfc
          numberOfEmployerRegistrations
          numberOfEmployees
          active
          services {
            id
          }
          type
          maxRuns
          contactName
          contactPhone
          billingData {
            id
            useCDFI
            taxRegime
            rfc
            postalcode
            email
            businessName
          }
          contactEmail
        }
        subscriptionPaid
        subscriptionURL
        name
        id
        numberOfEmployees
        rfc
        balance {
          amount
          currency
          exponent
        }
        active
        billingMode
        completedServices {
          id
          status
          businessRun {
            id
            businessName
            rfc
            active
          }
          reportFolder {
            id
            name
            files {
              id
              name
              path
              createdAt
            }
          }
        }
        billingDataArray {
          id
          useCDFI
          taxRegime
          rfc
          postalcode
          email
          businessName
        }
      }
      phone
      createdAt
      updatedAt
    }
  }
`;

export { GET_USER };
