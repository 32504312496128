import React, { useState } from "react";
import { Modal, Form, Input, Typography, Button, message } from "antd";
import { LockOutlined } from "@ant-design/icons";
import { UPDATE_PASSWORD } from "../requests";
import { useMutation } from "@apollo/client";
import console from "console-browserify";

const { Text } = Typography;

const UpdatePasswordModal = ({ visible, setVisible, user, userRefetch }) => {
  const [updatePasswordMutation] = useMutation(UPDATE_PASSWORD);
  const [form] = Form.useForm();
  const [formData, setFormData] = useState({
    currentPassword: "",
    newPassword: "",
    confirmPassword: "",
  });
  const [loadingUpdate, setLoadingUpdate] = useState(false);

  const handleInputChange = (fieldName, value) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      [fieldName]: value,
    }));
  };

  const validatePasswordConfirm = (_, value) => {
    const { newPassword } = formData;
    if (value && newPassword !== value) {
      return Promise.reject(new Error("Las contraseñas no coinciden"));
    }
    return Promise.resolve();
  };

  const updateData = async () => {
    setLoadingUpdate(true);

    try {
      const payload = {
        variables: {
          id: user?.id,
          currentPassword: formData.currentPassword,
          newPassword: formData.newPassword,
        },
      };

      const { data } = await updatePasswordMutation(payload);
      if (!data.updatePassword) {
        message.error("La contraseña actual no es la correcta");
        setLoadingUpdate(false);
        return;
      }
      await userRefetch();
      message.success("Operación realizada con éxito");
      setVisible(false);
      setLoadingUpdate(false);
      form.resetFields();
    } catch (err) {
      message.error("Hubo un error");
      console.log(err);
    }
  };

  const handleCancel = () => {
    setVisible(false);
  };

  return (
    <Modal
      title="Cambiar Contraseña"
      visible={visible}
      onCancel={handleCancel}
      footer={[
        <Button
          key="update"
          type="primary"
          onClick={updateData}
          loading={loadingUpdate}
        >
          Actualizar
        </Button>,
      ]}
    >
      <Form form={form} onFinish={updateData}>
        <div style={{ marginBottom: 10 }}>
          <Text style={{ fontSize: 13 }} strong>
            Contraseña Actual
          </Text>
          <Form.Item
            name="currentPassword"
            rules={[
              {
                required: true,
                message: "Por favor ingrese la Contraseña Actual",
              },
            ]}
          >
            <Input.Password
              onChange={(e) =>
                handleInputChange("currentPassword", e.target.value)
              }
              size="large"
              prefix={<LockOutlined />}
              placeholder="Contraseña Actual"
            />
          </Form.Item>
        </div>
        <div style={{ marginBottom: 10 }}>
          <Text style={{ fontSize: 13 }} strong>
            Nueva Contraseña
          </Text>
          <Form.Item
            name="newPassword"
            rules={[
              {
                required: true,
                message: "Por favor ingrese la Contraseña",
              },
            ]}
          >
            <Input.Password
              size="large"
              prefix={<LockOutlined style={{ color: "#C52790" }} />}
              type="password"
              placeholder="Contraseña"
              onChange={(e) => handleInputChange("newPassword", e.target.value)}
            />
          </Form.Item>
        </div>
        <div style={{ marginBottom: 10 }}>
          <Text style={{ fontSize: 13 }} strong>
            Confirmar Nueva Contraseña
          </Text>
          <Form.Item
            name="confirmPassword"
            dependencies={["newPassword"]}
            rules={[
              {
                required: true,
                message: "Por favor confirma tu contraseña",
              },
              {
                validator: validatePasswordConfirm,
              },
            ]}
          >
            <Input.Password
              size="large"
              type="password"
              prefix={<LockOutlined style={{ color: "#C52790" }} />}
              placeholder="Confirmar Contraseña"
              onChange={(e) =>
                handleInputChange("confirmPassword", e.target.value)
              }
            />
          </Form.Item>
        </div>
      </Form>
    </Modal>
  );
};

export default UpdatePasswordModal;
