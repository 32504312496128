import React from "react";
import MainLayout from "../../layout/main";
import { useUser } from "../../providers/user";
import { Navigate, Outlet } from "react-router-dom";
import { Spin } from "antd";

const PrivateRoutes = () => {
  const { user } = useUser();
  if (!user) {
    return <Navigate to="/login" />;
  } else {
    return (
      <MainLayout>
        {user == "Loading" ? (
          <Spin style={{ marginTop: 45 }} tip="Cargando..." size="large">
            <div className="content" />
          </Spin>
        ) : (
          <Outlet />
        )}
      </MainLayout>
    );
  }
};

export default PrivateRoutes;
