import React, { createContext, useCallback, useEffect, useState } from "react";
import cookies from "react-cookies";
import jwt from "jsonwebtoken";
import { useLazyQuery } from "@apollo/client";
import { GET_USER } from "./requests";
import { message } from "antd";
import axios from "axios";
import { apiRoute } from "../../utils/constants";

const UserContext = createContext({});

const apiClient = axios.create();

const UserProvider = ({ children }) => {
  const [user, setUser] = useState("Loading");
  const [isLogged, setIsLogged] = useState(false);

  const [
    userQuery,
    { data: userData, error: userError, refetch: userRefetch },
  ] = useLazyQuery(GET_USER, {
    fetchPolicy: "no-cache",
  });

  const getUser = useCallback(() => {
    const token = cookies.load("token");
    const tokenPayload = token ? jwt.decode(token) : undefined;
    if (tokenPayload) {
      userQuery({
        variables: {
          id: tokenPayload.id,
        },
      });
    } else {
      setUser(null);
      setIsLogged(false);
    }
  }, [userQuery]);

  useEffect(() => {
    if (userError) {
      message.error("Ha habido un error cargando el perfil!");
      setUser(null);
      setIsLogged(false);
    } else if (userData) {
      setUser(userData.user);
      setIsLogged(true);
    }
  }, [userData, userError]);

  useEffect(() => {
    getUser();
  }, [getUser]);

  const logout = () => {
    cookies.remove("token");
    setUser(null);
    setIsLogged(false);
  };

  const login = useCallback(async (body) => {
    const { data } = await apiClient.post(`${apiRoute}/authenticate`, body);
    if (data) {
      cookies.save("token", data.token, {});
      setUser(data.user);
      getUser();
      setIsLogged(true);
      return data?.user;
    }
    getUser();
  }, []);

  const signup = useCallback(async (body) => {
    const { data } = await apiClient.post(`${apiRoute}/register`, body);

    if (data) {
      cookies.save("token", data.token, {});
      setUser(data.user);
      getUser();
      setIsLogged(true);
      return data?.user;
    }
  }, []);

  const createCode = useCallback(async (body) => {
    const response = await apiClient.post(
      `${apiRoute}/create-confirmation-code`,
      body,
    );

    return response.data;
  }, []);

  const checkConfirmationCode = useCallback(async (body) => {
    const response = await apiClient.post(
      `${apiRoute}/check-confirmation-code`,
      body,
    );

    return response.data;
  }, []);

  const restorePassword = useCallback(async (body) => {
    const response = await apiClient.post(`${apiRoute}/restore-password`, body);

    return response.data;
  }, []);

  return (
    <UserContext.Provider
      value={{
        user,
        getUser,
        isLogged,
        setIsLogged,
        logout,
        login,
        signup,
        userRefetch,
        checkConfirmationCode,
        createCode,
        restorePassword,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};

export { UserContext };
export default UserProvider;
