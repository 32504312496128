import React from "react";
import { Layout, Typography } from "antd";
import { AuthWrapper, DisplayWrapper, ImageContainer } from "./elements";
import NiPSLogo from "./logo.png";

const { Text } = Typography;

const AuthLayout = ({ children }) => {
  return (
    <Layout style={{ display: "flex", flexDirection: "row" }}>
      <DisplayWrapper>
        <div style={{ marginLeft: "5vw", width: "35vw", marginTop: "20vh" }}>
          <Text strong style={{ color: "white", fontSize: 25 }}>
            No es un costo es un ahorro por eficiencia atado a una herramienta
            que genera un crecimiento exponencial
          </Text>
        </div>
        <ImageContainer>
          <img
            src={NiPSLogo}
            width={"100%"}
            alt="Audi-Imss logo"
            onClick={() => window.open("https://nips.com.mx", "_blank")}
          />
        </ImageContainer>
      </DisplayWrapper>
      <AuthWrapper>{children}</AuthWrapper>
    </Layout>
  );
};

export default React.memo(AuthLayout);
