import React, { useState } from "react";
import PropTypes from "prop-types";
import { Layout as Layer } from "antd";
import Sidebar from "./sidebar";
import Navbar from "./navbar";
import { useUser } from "../../providers/user";
// import Footer from "layouts/main/footer";

const MainLayout = ({ children }) => {
  const [collapsed, setCollapsed] = useState(false);
  const { user } = useUser();

  return (
    <Layer style={{ minHeight: "100vh", height: "auto" }}>
      {user?.type == "admin" && (
        <Sidebar collapsed={collapsed} onCollapse={setCollapsed} />
      )}
      <Layer>
        <Navbar />
        {children}
        {/* <Footer /> */}
      </Layer>
    </Layer>
  );
};

MainLayout.propTypes = {
  children: PropTypes.any.isRequired,
};

export default MainLayout;
