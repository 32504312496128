import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Image, Typography, Divider } from "antd";
import {
  FolderOpenTwoTone,
  ContactsTwoTone,
  ShopTwoTone,
  TagsTwoTone,
  BookTwoTone,
  DollarOutlined,
} from "@ant-design/icons";
import { breakpoints } from "../../../theme/media";
import { Menu, Sider } from "./elements";
import logo from "./logo-audi-imss.jpeg";
import { useUser } from "providers/user";
import { useLocation } from "react-router-dom";

const { Item } = Menu;
const { Text } = Typography;

const Sidebar = ({ collapsed, onCollapse }) => {
  const { user } = useUser();
  const [isLg, toggleLg] = useState(window.innerWidth > breakpoints.lg);
  const location = useLocation();

  const type = user.type;

  const updateWidth = () => {
    toggleLg(window.innerWidth > breakpoints.lg);
  };

  useEffect(() => {
    window.addEventListener("resize", updateWidth);

    return () => window.removeEventListener("resize", updateWidth);
  }, []);

  let routes = (
    <>
      <Item icon={<FolderOpenTwoTone twoToneColor="#C52790" />} key="/folders">
        <Link to="/folders">
          <Text strong>Carpetas</Text>
        </Link>
      </Item>
    </>
  );

  let routesAdmin = (
    <>
      <Item icon={<BookTwoTone twoToneColor="#C52790" />} key="/services">
        <Link to="/services">
          <Text strong>Servicios</Text>
        </Link>
      </Item>
      <Item icon={<ShopTwoTone twoToneColor="#C52790" />} key="/organizations">
        <Link to="/organizations">
          <Text strong>Organizaciones</Text>
        </Link>
      </Item>
      <Item icon={<ContactsTwoTone twoToneColor="#C52790" />} key="/clients">
        <Link to="/clients">
          <Text strong>Usuarios</Text>
        </Link>
      </Item>
      <Item icon={<TagsTwoTone twoToneColor="#C52790" />} key="/discounts">
        <Link to="/discounts">
          <Text strong>Descuentos</Text>
        </Link>
      </Item>
      <Item icon={<DollarOutlined twoToneColor="#C52790" />} key="/payments">
        <Link to="/payments">
          <Text strong>Pagos</Text>
        </Link>
      </Item>
    </>
  );
  return (
    <Sider
      breakpoint="lg"
      collapsedWidth={isLg ? "80" : "0"}
      trigger={null}
      collapsed={collapsed}
      collapsible
      onCollapse={onCollapse}
      style={{
        minHeight: "100vh !important",
        height: "auto",
        maxHeight: "10000vh",
        background: "white",
      }}
    >
      <Image
        style={{
          margin: "15px auto",
          display: "block",
          paddingLeft: 40,
          paddingRight: 40,
        }}
        height={40}
        preview={false}
        src={logo}
        alt=""
      />
      <Divider />
      <Menu
        theme="light"
        defaultSelectedKeys={location.pathname}
        // selectedKeys={history.location.pathname.toLowerCase()}
        mode="inline"
      >
        {type === "admin" ? routesAdmin : routes}
      </Menu>
      <div style={{ minHeight: "100vh" }}></div>
    </Sider>
  );
};

export default Sidebar;
