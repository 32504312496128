import styled from "styled-components";
import { Layout, Button, Menu as CommonMenu, Typography } from "antd";

const { Header } = Layout;

const { Title } = Typography;

const NavbarContainer = styled(Header)`
  background: #ffffff;
  padding-right: 10px !important;
  padding-left: 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
`;

const Menu = styled(CommonMenu)`
  align-items: center;
  height: fit-content;
  display: flex;
`;

const ButtonColor = styled(Button)`
  background: linear-gradient(
    120deg,
    rgba(197, 39, 144, 1) 0%,
    rgba(238, 56, 49, 1) 71%,
    rgba(255, 205, 52, 1) 100%
  );
  color: white;
`;

const TitleColor = styled(Title)`
  color: #ee3831 !important;
`;

const NameContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-right: 15px;
`;

const ProfileButton = styled(Button)`
  height: 100%;
  display: flex;
  align-items: center;
`;

export {
  NavbarContainer,
  Menu,
  ProfileButton,
  NameContainer,
  ButtonColor,
  TitleColor,
};
