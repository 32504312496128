import React, { useMemo, useState } from "react";
import { Dropdown, Avatar, Typography, Row, Image, Spin } from "antd";
import shortid from "shortid";
import { Link, useLocation, useSearchParams } from "react-router-dom";
import {
  DownOutlined,
  LogoutOutlined,
  SettingOutlined,
  WhatsAppOutlined,
  BookOutlined,
  FormOutlined,
  KeyOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import {
  NavbarContainer,
  Menu,
  ProfileButton,
  NameContainer,
  ButtonColor,
} from "./elements";
import { useUser } from "../../../providers/user";
import theme from "theme/index";
import SettingsModal from "views/private/routes/crm/routes/settings/index";
import UpdatePasswordModal from "views/private/routes/crm/routes/settings/update-password/index";
import logo from "../sidebar/logo-audi-imss.jpeg";

const { Text, Title } = Typography;

function NavBar() {
  const { user, logout, userRefetch } = useUser();
  const location = useLocation();
  const [searchParams] = useSearchParams();
  const [settingsVisible, setSettingsVisible] = useState(false);
  const [updatePasswordVisible, setUpdatePasswordVisible] = useState(false);

  const mapPathNames = {
    "/folders":
      user?.type == "admin" ? (
        "Carpetas"
      ) : (
        <Image
          style={{
            marginTop: "5px",
            display: "block",
            paddingLeft: 40,
            paddingRight: 40,
          }}
          height={40}
          preview={false}
          src={logo}
          alt=""
        />
      ),
    "/organizations": "Organizaciones",
    "/clients": "Usuarios",
    "/reports": "Reportes",
    "/discounts": "Descuentos",
    "/services": "Servicios",
    "/payments": "Pagos",
    "/business-run": "Razones Sociales",
  };

  const mappedPathName =
    mapPathNames[location?.pathname] ?? searchParams.get("name") ?? "Carpetas";

  const handleLogout = async () => {
    await logout();
  };

  const userHasCompletedServices = useMemo(() => {
    if (!user?.organization || user?.type == "admin") return false;
    if (Array.isArray(user?.organization?.completedServices)) {
      return user?.organization?.completedServices.length > 0;
    }
    return false;
  }, [user?.organization]);

  return (
    <NavbarContainer>
      <Title style={{ marginTop: 10 }} level={3}>
        {mappedPathName}
      </Title>
      <Row style={{ alignItems: "center" }}>
        {location.pathname === "/folders" && (
          <Link to="/business-run" style={{ marginLeft: 20 }}>
            <ButtonColor icon={<PlusOutlined />}>
              Agregar Razones Sociales
            </ButtonColor>
          </Link>
        )}
        {location.pathname === "/business-run" &&
          user?.organization?.termsAndConditionsAccepted && (
            <Link to="/folders" style={{ marginLeft: 20 }}>
              <ButtonColor icon={<FormOutlined />}>Servicios</ButtonColor>
            </Link>
          )}
        {userHasCompletedServices && (
          <>
            <Link to="/reports" style={{ marginLeft: 20 }}>
              <ButtonColor icon={<BookOutlined />}>Reportes</ButtonColor>
            </Link>
            <Link to="/folders" style={{ marginLeft: 20 }}>
              <ButtonColor icon={<FormOutlined />}>Servicios</ButtonColor>
            </Link>
          </>
        )}
        <ButtonColor
          icon={<WhatsAppOutlined />}
          onClick={() => {
            window
              .open(
                "https://wa.me/8186024857?text=Hola, quisiera tener soporte con la plataforma de AUDI-IMSS.",
                "_blank",
              )
              .focus();
          }}
          style={{ marginLeft: 20 }}
        >
          Soporte
        </ButtonColor>
        <Dropdown
          trigger={["click"]}
          overlay={
            <Menu mode="vertical">
              <Menu.ItemGroup title="Cuenta">
                <Menu.Item
                  key={shortid.generate()}
                  onClick={() => setSettingsVisible(true)}
                  icon={<SettingOutlined />}
                >
                  Configuración
                </Menu.Item>
                <Menu.Item
                  key={shortid.generate()}
                  onClick={() => setUpdatePasswordVisible(true)}
                  icon={<KeyOutlined />}
                >
                  Cambiar Contraseña
                </Menu.Item>
              </Menu.ItemGroup>
              <Menu.ItemGroup title="Sesión">
                <Menu.Item
                  key={shortid.generate()}
                  onClick={() => handleLogout()}
                  icon={<LogoutOutlined style={{ color: "red" }} />}
                >
                  <Link to={"/login"} style={{ color: "red" }}>
                    Salir
                  </Link>
                </Menu.Item>
              </Menu.ItemGroup>
            </Menu>
          }
        >
          <ProfileButton type="text">
            <Avatar style={{ marginRight: 15 }} size={35}></Avatar>
            <NameContainer>
              <Text
                style={{ fontSize: 12, color: theme.colors.primary }}
                type="secondary"
              >
                Hola,
              </Text>
              {user == "Loading" && <Spin style={{ marginRight: 120 }} />}
              <Text
                style={{ marginTop: -5, color: theme.colors.primary }}
                strong
              >
                {user?.name}
              </Text>
            </NameContainer>
            <DownOutlined />
          </ProfileButton>
        </Dropdown>
      </Row>
      <SettingsModal
        visible={settingsVisible}
        setVisible={setSettingsVisible}
        user={user}
        userRefetch={userRefetch}
      />
      <UpdatePasswordModal
        visible={updatePasswordVisible}
        setVisible={setUpdatePasswordVisible}
        user={user}
        userRefetch={userRefetch}
      />
    </NavbarContainer>
  );
}

export default NavBar;
